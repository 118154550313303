import React from 'react'
import { Menu } from 'antd'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import './index.less'
const IconFont = Loader.loadBaseComponent('IconFont')
const titleInfo = ['keyPersonnel', 'outsider', 'eventMonitor', 'privateNet', 'epidemicPersonTrack']
const menuListInfo = [
  ['keyPersonnelHistory', 'keyPersonnelTaskView', 'keyPersonnelLibraryView'],
  ['outsiderHistory', 'outsiderTaskView', 'outsiderLibraryView'],
  ['eventHistoryNotify', 'eventTaskView'],
  ['privateNetHistory', 'privateNetTaskView', 'privateNetLibraryView'],
  ['epidemicPersonTrackHistoryList', 'epidemicPersonTrackTask', 'epidemicPersonTrackLibrary']
]

@withRouter
@inject('menu', 'tab')
@observer
class MonitorNavigation extends React.Component {
  constructor(props) {
    super(props)
    const { libType = 1, menu } = this.props
    const menuListNames = menuListInfo[libType - 1]
    this.title = menu.authList.find(v => v.name === titleInfo[libType - 1]) || {}
    this.menuList = menu.authList.filter(item => menuListNames.indexOf(item.name) > -1) || []
  }
  handleClick = e => {
    const { tab, location } = this.props
    tab.goPage({
      moduleName: e.key,
      location,
      isUpdate: true,
      action: 'replace'
    })
  }
  render() {
    const { contentClass = '', libType = 1, currentMenu, children = null } = this.props
    const sceneCode = BaseStore.user.appInfo.sceneCode
    const monitorLabel = Dict.map.monitorLabel[sceneCode]
    return (
      <React.Fragment>
        <div className="monitor-container-outer">
          <div className="monitor-header">
            <div className="monitor-title">{this.title.menuName}</div>
            <Menu onClick={this.handleClick} mode="horizontal" className="menu-tab-style" selectedKeys={[currentMenu]}>
              {Array.isArray(this.menuList) &&
                this.menuList.map((item, index) => {
                  let title = item.title || item.menuName
                  if (index == 2) {
                    if (libType === 1) {
                      title = monitorLabel.keyPerson.libLabel + '管理'
                    } else if (libType === 2) {
                      title = monitorLabel.outsider.libLabel + '管理'
                    }
                  }
                  return (
                    <Menu.Item key={item.name}>
                      {item.icon && <IconFont type={item.icon} style={{ fontSize: '16px' }} theme="outlined" />}
                      {title}
                    </Menu.Item>
                  )
                })}
            </Menu>
          </div>
          <div className={`monitor-content ${contentClass}`}>{children}</div>
        </div>
      </React.Fragment>
    )
  }
}
export default MonitorNavigation
